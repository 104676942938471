import { sanitize } from "isomorphic-dompurify";
import { useEffect, useState } from "react";

import {
  LayoutType,
  DividerType,
  PortraitSliderType,
  PageType,
} from "../../utils/baseTypes";
import Divider from "../Divider/Divider";
import { Section } from "../Global/Section";
import { PortraitSlider } from "./PortraitSlider";
import { WP_Post, WP_Term } from "wp-types";
import { motion } from "framer-motion";
import { blur_opacity_variant, stagger_variant } from "../../utils/transition";
import { CTASlideType } from "./PortraitCTASlide";

const renameKeys = (keysMap: any, obj: any) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] },
    }),
    {}
  );

const renameObjMain = {
  image_slider: "portraitslider_image_slider",
  title: "portraitslider_title",
  overline: "portraitslider_overline",
  media: "portraitslider_media",
  content: "portraitslider_content",
  link: "portraitslider_link",
  video: "portraitslider_video",
  poster: "portraitslider_poster",
};

const renameObjMedia = {
  type: "portraitslider_media_type",
  image: "portraitslider_media_image",
  svg: "portraitslider_media_svg",
  svg_animation: "portraitslider_media_svg_animation",
};

const changeType = (elements: any[]): PortraitSliderType[] => {
  const sliderData = [];

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    const renamedElement = renameKeys(renameObjMain, element);
    //! cant add type because we are changing the keys had to use ts-ignore
    // @ts-ignore
    renamedElement["portraitslider_media"] = renameKeys(
      renameObjMedia,
      // @ts-ignore
      renamedElement["portraitslider_media"]
    );

    sliderData.push(renamedElement);
  }
  return sliderData as PortraitSliderType[];
};

export type PortraitSliderModuleType = {
  acf_fc_layout: "slider";
  slider: {
    headline: string;
    htype_tag: "h2" | "h3";
    slider_type:
      | "textmedia-slider"
      | "image-slider"
      | "video-slider"
      | "portfolio-slider"
      //todo remove this when correct "portfolio-slider" value added in backend
      | "portrait-slider";
    divider: DividerType;
    elements: any[];
    portfolio_slider_elements: WP_Post[];
    select_portrait_slider_posts:
      | "manual"
      | "categories-random"
      | "categories-date";

    portfolio_categories: WP_Term[];
    cta_last_element: boolean;
    cta_slider: CTASlideType;
  };
  layout: LayoutType;
};

interface PortraitSliderProps {
  data: PortraitSliderModuleType;
  PortraitSliderModulePortfolio: PageType[];
}

const PortraitSliderModule = ({
  data,
  PortraitSliderModulePortfolio,
}: PortraitSliderProps) => {
  const { abstand, background } = data.layout;

  const { divider, headline, cta_last_element, cta_slider } = data.slider;
  const gap = abstand === "default" ? "" : abstand;

  const { elements, slider_type, htype_tag } = data.slider;
  const [sliderData, setSliderData] = useState<PortraitSliderType[]>([]);

  useEffect(() => {
    //! used  the funtion changeType the keys are different for data received
    //! on the header page and module currently teh data is changed to how it received
    //! in header page
    const sliderData_ = changeType(elements);
    setSliderData(sliderData_);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let HeadlineJSX;
  switch (htype_tag) {
    case "h2":
      HeadlineJSX = (
        <motion.h2 variants={blur_opacity_variant}>
          <strong
            dangerouslySetInnerHTML={{ __html: sanitize(headline) }}
          ></strong>
        </motion.h2>
      );
      break;
    case "h3":
      HeadlineJSX = (
        <motion.h3 variants={blur_opacity_variant}>
          <strong
            dangerouslySetInnerHTML={{ __html: sanitize(headline) }}
          ></strong>
        </motion.h3>
      );
      break;
    default:
      HeadlineJSX = (
        <motion.h2 variants={blur_opacity_variant}>
          <strong
            dangerouslySetInnerHTML={{ __html: sanitize(headline) }}
          ></strong>
        </motion.h2>
      );
      break;
  }

  return (
    <Section
      className={`portrait-slider-module divider  ${background} ${gap}`}
      disableColumns
      isClipped={false}
    >
      <motion.div
        initial="initial"
        whileInView={"animate"}
        variants={stagger_variant}
        viewport={{ once: true }}
        className="columns is-multiline"
      >
        {divider && divider.divider_on && (
          <div className="column is-12">
            <Divider data={divider} background={background} />
          </div>
        )}
        <div className="column is-12">
          <div className="columns">
            <div className="column is-6">{HeadlineJSX}</div>
          </div>
        </div>
        <div className="column is-12">
          <PortraitSlider
            data={sliderData}
            sliderType={slider_type}
            background={background}
            sectionClassName={"is-gapless"}
            portraitSliderPortfolio={PortraitSliderModulePortfolio}
            cta_slider={cta_last_element ? cta_slider : undefined}
          />
        </div>
      </motion.div>
    </Section>
  );
};
export default PortraitSliderModule;
